import React from "react";
import { Router, useLocation } from "@reach/router";
import loadable from "@loadable/component";

import "../../assets/sass/style.scss";
import Header from "./Header";
import Footer from "./Footer";
import Helmet from "react-helmet";

export const API_ADDR = "https://private.narsharab.kz";
export const BASE_TITLE = "Shipudim";

const QR_PAGES = {
  "sary-arka": {},
  mira: {},
  auezova: {},
  "karasay-batyra": {},
  "tole-bi": {},
  "abylay-hana": {},
};

const Home = loadable(() => import("../home"));
const Cart = loadable(() => import("../cart"));
const Menu = loadable(() => import("../menu"));
const Deals = loadable(() => import("../deals"));
const About = loadable(() => import("../about"));
const Jobs = loadable(() => import("../jobs"));
const LegalInformation = loadable(() => import("../legal-information"));
const ContactOffer = loadable(() =>
  import("../legal-information/contract-offer")
);
const PersonalDataAgreement = loadable(() =>
  import("../legal-information/personal-data-agreement")
);
const PrivacyPolicy = loadable(() =>
  import("../legal-information/privacy-policy")
);
const Qr = loadable(() => import("../qr"));

const generateQrPages = () => {
  let qrPages = [];
  for (let street in QR_PAGES) {
    let menuFileName = street;

    if (["sary-arka", "mira", "auezova", "abylay-hana"].includes(street)) {
      menuFileName = "sary-arka";
    }

    qrPages.push(
      <Qr
        key={`restaurant-${street}`}
        path={`/qr/${street}/`}
        menuFileName={menuFileName}
      />
    );
  }
  return qrPages;
};

const App = () => {
  const location = useLocation().pathname;

  const qrPattern = /(\/qr\/).*/;

  let renderComponent;

  if (qrPattern.test(location) || location === "/qr") {
    renderComponent = false;
  } else renderComponent = true;

  return (
    <>
      <Helmet>
        <title>{BASE_TITLE}</title>
      </Helmet>

      {renderComponent ? <Header /> : <></>}

      <Router primary={false}>
        <Home path="/*" />
        <Menu path="/menu/*" />
        <Deals path="/deals/" />
        <Cart path="/cart/" />
        <About path="/about/" />
        <Jobs path="/jobs/" />
        <LegalInformation path="/legal-information/" />
        <ContactOffer path="/legal-information/contract-offer" />
        <PersonalDataAgreement path="/legal-information/personal-data-agreement" />
        <PrivacyPolicy path="/legal-information/privacy-policy" />
        <Qr key="qr" path="/qr/" menuFileName="shipudim" />
        {generateQrPages()}
      </Router>

      {renderComponent ? <Footer /> : <></>}
    </>
  );
};
export default App;
